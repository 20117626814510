/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  function sticky_hold($el) {
    if($el.length > 0) {
      var target = $el.attr('data-target') ? $el.data("target") : false;
      var st = $(window).scrollTop();
      if(target !== false) {
        target = $(target);
        if(target.length > 0) {
          var ts = target.offset().top;
          if(st >= ts) {
            $el.addClass("sticky-top sticky-active");
          }
          if(st < 1) {
            $el.removeClass("sticky-top sticky-active");
          }
        }
      } else {
        $el.addClass("sticky-top");
        if(st > 0) {
          $el.addClass("sticky-active");
        } else {
          $el.removeClass("sticky-active");
        }
      }
    }
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        WebFont.load({
          google: {
            families: ['Salsa']
          }
        });

        new WOW().init();

        $('[href="#reserve-now"], [href="#open-menu"]').on("click", function(e) {
          if($(this).attr('data-target')) {
            var target = $(this).data("target");
            var booking = $(target);
            if(booking.length > 0) {
              booking.removeClass("closed");
              booking.addClass("open");
              $("body").addClass("sidebar-open");
              e.preventDefault();
              return false;
            }
          }
        });

        $('.sidebar-menu .close').on("click", function() {
          if($(this).attr('data-target')) {
            var target = $(this).data("target");
            target = $(target);
            if(target.length > 0) {
              target.removeClass("open");
              target.addClass("closed");
              $("body").removeClass("sidebar-open");
            }
          }
        });

        $(document).on("scroll", function() {
          var $el = $(".sticky-hold");
          sticky_hold($el);
        });
        sticky_hold($(".sticky-hold"));

        $(".canopy-menu").find(".menu-item-has-children").on("click", function() {
          $(this).toggleClass("open-submenu");
        });

        var delay = 0;
        $.each($(".canopy-menu").find(".menu-item-has-children").find("li"), function() {
          $(this).css("animation-delay", delay+"ms");
          delay += 90;
        });

        if($("[data-cover]").length > 0) {
          $.each($("[data-cover]"), function() {
            var cover = $(this).data("cover");
            if(cover) {
              $(this).css("background-image", "url(" + cover + ")");
            }
          });
        }
      },
      finalize: function() {
        $.each($(".add_to_cart_button, .form-submit .submit, .single_add_to_cart_button, .coupon .button, [name=\"update_cart\"], .checkout-button, .quote-button a"), function() {
          var e = $(this);
          if(e.children("span").length <= 0) {
            var text = e.text();
            e.empty();
            $("<span>" + text + "</span>").appendTo(e);
          }
        });
        $(document).on('click', '.plus', function(e) {
            $input = $(this).prev('input.qty');
            var val = parseInt($input.val());
            var step = $input.attr('step');
            step = 'undefined' !== typeof(step) ? parseInt(step) : 1;
            $input.val( val + step ).change();
        });
        $(document).on('click', '.minus', function(e) {
            $input = $(this).next('input.qty');
            var val = parseInt($input.val());
            var step = $input.attr('step');
            step = 'undefined' !== typeof(step) ? parseInt(step) : 1;
            if (val > 0) {
                $input.val( val - step ).change();
            } 
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        var header = $("#header-video");
        var video = header.data("video");
        var poster = header.data("poster");
        header.css("background-image", "url(" + poster + ")");
        var template  = '<video id="video-background" poster="' + poster + '" autoplay loop muted plays-inline playsinline>';
        template     +=     '<source src="' + video + '" type="video/mp4">';
        template     += '</video>';
        $(template).appendTo(header);

        var $btn_play_video = $('.button-play');
        if($btn_play_video.length > 0) {
          new ModalVideo('.button-play');
        }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.